<template>
  <div class="oIframe">
    <div class="iframe" id="third-iframe"></div>
  </div>
</template>
<script>
// 引入组件
import { thirdCodeUrl } from "./api.js";
export default {
  name: "oIframe",
  components: {},
  data() {
    return {
      url: "",
      modelKey: "",
    };
  },
  watch: {
    $route: {
      handler(val) {
        this.handleIframe();
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {},
  mounted() {},
  methods: {
    async handleIframe() {
      this.modelKey = this.$route.query.modelKey;
      let res = await this.getThirdCode();
      if (res.code == 200) {
        let code = res.data;
        this.url = `${this.$route.query.path}?code=${code}&userId=${this.$store.state.app.userInfo.userId}`;
        let oEleIframe = document.getElementById("third-iframe");
        if (oEleIframe && oEleIframe.childNodes && oEleIframe.childNodes[0]) {
          oEleIframe.removeChild(oEleIframe.childNodes[0]);
        }
        let oIframe = document.createElement("iframe");
        oIframe.style.cssText = "width:100%;height:100%;border:medium none;";
        oIframe.src = this.url;
        oIframe.frameborder = 0;
        oEleIframe.appendChild(oIframe);
        console.log(oEleIframe.childNodes);
      }
    },
    async getThirdCode() {
      let params = {
        modelKey: this.modelKey,
        userId: this.$store.state.app.userInfo.userId,
      };
      let res = await this.$axios.post(`${thirdCodeUrl}`, params);
      return res;
    },
  },
  beforeDestroy() {
    let oEleIframe = document.getElementById("#third-iframe");
    if (oEleIframe && oEleIframe.childNodes && oEleIframe.childNodes[0]) {
      oEleIframe.removeChild(oEleIframe.childNodes[0]);
    }
  },
};
</script>
<style lang="less">
.oIframe {
  box-sizing: border-box;
  height: 100%;
  padding-top: 50px;
  .iframe {
    height: 100%;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
